jQuery(document).ready(function($) {
	startMap();
});

/*jQuery(document).on({
    click: function (e) {
		jQuery('.map-filters a').removeClass('active');
		jQuery(this).addClass('active');

		hash = jQuery(this).attr('data-cat');

		categoryFilter(hash);
    }
}, '.map-filters a');
*/
