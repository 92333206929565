// small plugin to make it easier to bind the "on enter key pressed" a event
// Usage:
// jQuery("#input").enterKey(function () {
//     alert('Enter!');
// })
jQuery.fn.enterKey = function (fnc) {
    return this.each(function () {
        jQuery(this).keypress(function (ev) {
            var keycode = (ev.keyCode ? ev.keyCode : ev.which);
            if (keycode === 13) {
                fnc.call(this, ev);
            }
        });
    });
};

var search_in_site = function (text, lang) {
  if (text) {
    location.href = "/search/" + encodeURI(text) + "?lang=" + lang;
  }
};


jQuery(function(){

  jQuery(".search-bar").on("click", "#search-button", function(e) {
    search_in_site(jQuery("#search-text").val(), jQuery("#search-lang").val());
  });

  jQuery("#search-text").enterKey(function () {
    search_in_site(jQuery("#search-text").val(), jQuery("#search-lang").val());
  });

});
