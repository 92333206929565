jQuery(document).on({
	click: function () {
		var item = jQuery('#' + jQuery(this).attr('data-open'));

		item.slideToggle(200, function() {
			if((item).is(":visible")) {
				jQuery('html,body').animate({
	        		scrollTop: item.offset().top - 60
		        }, 800);
			}
		});
	}
}, '.rmb-link');

jQuery(document).on({
	click: function () {
		var item = jQuery('#' + jQuery(this).attr('data-open'));
		var itemScroll = jQuery(('#' + jQuery(this).attr('data-open')).replace("-content", ""));
		jQuery(this).toggleClass('rlp-close');

		item.slideToggle(200, function() {
			if (jQuery(this).is(':visible') && !jQuery(this).hasClass('row') && !jQuery(this).hasClass('service-hide') ) {
				jQuery(this).css('display','inline-block');
			}

			if((item).is(":visible")) {
				jQuery('html,body').animate({
	        		scrollTop: itemScroll.offset().top - 60
		        }, 800);
			}
		});
	}
}, '.rmb-link-plus');

if(window.location.hash) {
	var toOpen = window.location.hash;
	toOpen = toOpen.replace("#", "");

	jQuery(".rmb-link-plus[data-open='" + toOpen + "-content']").click();
}
