var map = null;
var tmpZoom = 2;
var infowindow = null;
var markers = [];
var markersIndex = [];
var markersOptions = [];
var markerClusterer = null;
var infowindowList = [];
var iconMarkerUrl = "<?php echo get_stylesheet_directory_uri(); ?>/theme/assets/images/iconMarker.png";

function initializeMap(mapElement) {
    //console.log("initializeMap()");

    var styles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];

	var styledMap = new google.maps.StyledMapType(styles, { name: "New Stamen Map" });

	var options = {
		center: new google.maps.LatLng(41.87194,12.56738),
			/*parseFloat(document.getElementById("longitude").value),
			parseFloat(document.getElementById("latitude").value)),*/
		zoom: 2,
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP /*, 'map_style'*/]
		},
		zoomControl: true,
		mapTypeControl: false,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.LARGE,
			position: google.maps.ControlPosition.LEFT_CENTER
		},
		navigationControl: true,
		streetViewControl: true,
		draggable: true,
		scrollwheel: false,
	};

	map = new google.maps.Map(document.getElementById(mapElement), options);
	//console.log('test');

	map.mapTypes.set('map_style', styledMap);
	map.setMapTypeId('map_style');

}

function map_recenter(latlng,offsetx,offsety) {
    var point1 = map.getProjection().fromLatLngToPoint(
        (latlng instanceof google.maps.LatLng) ? latlng : map.getCenter()
    );
    var point2 = new google.maps.Point(
        ( (typeof(offsetx) === 'number' ? offsetx : 0) / Math.pow(2, map.getZoom()) ) || 0,
        ( (typeof(offsety) === 'number' ? offsety : 0) / Math.pow(2, map.getZoom()) ) || 0
    );
    map.panTo(map.getProjection().fromPointToLatLng(new google.maps.Point(
        point1.x - point2.x,
        point1.y + point2.y
    )));
}

function addMarker(position) {

	var marker = new google.maps.Marker({
		position: position[0],
		draggable: false,
		//animation: google.maps.Animation.DROP,
		//flat: false,
		icon: position[7],
		title: position[1],
		metadata: { id: position[2] },
		zIndex: position[11],
	});
	//marker.metadata = { id: position[2] };
	markers.push(marker);
	markersIndex[position[2]] = marker;
	//markersIndex["store-item-" + position[2]] = marker;
	//console.log(marker);

	//$('#store-item-' + position[2]).removeClass('highlight');
	google.maps.event.addListener(marker, 'displaywindow', function() {
		var contentString = "<div class='stilmas-hqinfobox'>";
		contentString += "<div class='stilmas-infobox-content'><div class='title'>" + position[1] + "</div>";
    contentString += "<div class='infowindow-address'>" + position[5] + "</div>";
    contentString += "<div class='infowindow-cap-city'>" + position[6] + " </div>";
    contentString += "<div>" + position[12] + "</div>";
	//	contentString += position[10];
		contentString += "</div>";

		contentString += "</div></div>";

    /*
    var contentString = "<div class='stilmas-hqinfobox' style='background-image: url(" + position[9] + "); background-position: 30px 90px;'>";
    contentString += "<div class='stilmas-infobox-content' style='background-image: url(" + position[8] + ");  background-position: 30px 90px;'><div class='title'>" + position[1] + "</div>";
    contentString += "<span class='infowindow-address'>" + position[5] + " |</span>";
    contentString += "<span class='infowindow-cap-city'>" + position[6] + " <span>|</span> <strong>" + position[4] + "<strong></span>";
    contentString += "<div class='contact'><div class='phone'>" + position[2] + "</div><div class='email'><a href='mailto:" + position[3] + "'>" + position[3] + "</a></div></div>";

    contentString += "</div></div>";*/
		//console.log(position);
		if (infowindow) {
			infowindow.close();
		}

		var myOptions = {
			content: contentString,
			disableAutoPan: false,
			maxWidth: 0,
			pixelOffset: new google.maps.Size(-200, -(260 + 65 + 20)),
			zIndex: null,
			boxStyle: {
				width: "400px",
				height: "260px",
			},
			closeBoxMargin: "0",
			closeBoxURL: "/wp-content/themes/polynt/assets/images/close-btn.jpg",
			infoBoxClearance: new google.maps.Size(1, 1),
			isHidden: false,
			pane: "floatPane",
			enableEventPropagation: false
		};

		infowindow = new InfoBox(myOptions);
		infowindow.open(map, marker);
	});

	google.maps.event.addListener(marker, 'click', function() {
		//new google.maps.event.trigger( marker, 'displaywindow' );
		//map.setCenter(marker.getPosition());
		if(map.getZoom() < 3){
			map.setZoom(3);
		}
		map_recenter(marker.getPosition(), 50, 0);

        jQuery.fancybox({
        	type: 'ajax',
        	ajax: {
        		dataType : 'html',
        		headers  : { 'X-fancyBox': true }
        	},
        	maxWidth	: '80%',
        	fitToView	: true,
        	width		: '100%',
        	height		: 'auto',
            href        : "/wp-admin/admin-ajax.php?action=getcontactfancy&pid=" + marker.metadata.id,
        	//maxHeight	: 500,
        	autoSize	: true,
        	closeClick	: false,
        	openEffect	: 'fade',
        	closeEffect	: 'fade',
        	//topRatio : '0.8',
        	padding		: 0,
        	afterClose: function() {
        		window.location.hash = '!'; // Trick to prevent scrolling ( http://stackoverflow.com/a/17108603/1474399 )
        	},
        	afterShow: function() {

        	}
        });
	});
}

function refreshMap() {
	for (var i = 0; i < markers.length; i++) {
		markers[i].setMap(map);
	}
}

function searchstart() {
	var link = '/wp-admin/admin-ajax.php?action=getstilmaslocations';

	jQuery.getJSON(link, function( data ) {
		markers = [];

		jQuery.each(data, function(key, val) {
      //array con info del pin
			addMarker([new google.maps.LatLng(val.latitude, val.longitude), val.post_title, val.ID, val.website, val.citta, val.indirizzo, val.cap, val.pin, val.icon, val.location_img, val.content, val.zindex, val.filter_thumb]);
		});

        console.log(markers);

		var bounds = new google.maps.LatLngBounds();

		if (markers.length > 0) {

			if (tmpZoom === 6) {
				bounds = new google.maps.LatLngBounds();
				for ( i = 0; i < markers.length; i++) {
					bounds.extend(markers[i].getPosition());
				}
				map.fitBounds(bounds);
			}

			if (map.getZoom() < 14) {
				refreshMap();
			}
			else {
				for ( i = 0; i < markers.length; i++) {
					markers[i].setMap(map);
				}
			}
		}
	});
}

function addHQMarker(position) {

	var marker = new google.maps.Marker({
		position: position[0],
		draggable: false,
		icon: position[7],
		title: position[1],
		metadata: { id: position[2] }
	});

	markers.push(marker);
	markersIndex[position[2]] = marker;

	var contentString = "<div class='stilmas-hqinfobox' style='background-image: url(" + position[9] + "); background-position: 30px 90px;'>";
	contentString += "<div class='stilmas-infobox-content' style='background-image: url(" + position[8] + ");  background-position: 30px 90px;'><div class='title'>" + position[1] + "</div>";
	contentString += "<span class='infowindow-address'>" + position[5] + " |</span>";
	contentString += "<span class='infowindow-cap-city'>" + position[6] + " <span>|</span> <strong>" + position[4] + "<strong></span>";
	contentString += "<div class='contact'><div class='phone'>" + position[2] + "</div><div class='email'><a href='mailto:" + position[3] + "'>" + position[3] + "</a></div></div>";

	contentString += "</div></div>";
	//console.log(position);
	if (infowindow) {
		infowindow.close();
	}

	var myOptions = {
		content: contentString,
		disableAutoPan: false,
		maxWidth: 0,
		pixelOffset: new google.maps.Size(100, -150),
		zIndex: null,
		boxStyle: {
			width: "360px",
			height: "200px",
		},
		closeBoxMargin: "0",
		closeBoxURL: "/wp-content/themes/polynt/assets/images/close-btn.jpg",
		infoBoxClearance: new google.maps.Size(1, 1),
		isHidden: false,
		pane: "floatPane",
		enableEventPropagation: false
	};

	infowindow = new InfoBox(myOptions);
	infowindow.open(map, marker);

	if (infowindow && (jQuery(window).width() < 768)) {
		infowindow.close();
	}

	map.panTo(marker.getPosition());
	map.setZoom(14);
	//map_recenter(marker.getPosition(), -195, 0);

	google.maps.event.addListener(infowindow, 'closeclick', function() {
		map_recenter(markers[0].getPosition(), 0, 0);
	});

	google.maps.event.addListener(marker, 'click', function() {
		infowindow.close();
		infowindow.open(map, marker);

		map_recenter(marker.getPosition(), -195, 0);
	});
}

function displayHeadquarters()
{
	var link = '/wp-admin/admin-ajax.php?action=getotusheadquarter';

	jQuery.getJSON(link, function( data ) {
		markers = [];

		jQuery.each(data, function(key, val) {
			addHQMarker([new google.maps.LatLng(val.latitude, val.longitude), val.post_title, val.telefono, val.email, val.citta, val.indirizzo, val.cap, val.pin, val.icon, val.location_img, val.filter_thumb]);
		});

		if (markers.length > 0) {
			for ( i = 0; i < markers.length; i++) {
				markers[i].setMap(map);
			}
		}
	});
}

function displayPin(pid)
{
	var link = '/wp-admin/admin-ajax.php?action=getstilmasgeoloc&pid=' + pid;

	jQuery.getJSON(link, function( data ) {
		markers = [];

		jQuery.each(data, function(key, val) {
			addHQMarker([new google.maps.LatLng(val.latitude, val.longitude), val.post_title, val.telefono, val.email, val.citta, val.indirizzo, val.cap, val.pin, val.icon, val.location_img, val.filter_thumb]);
		});

		if (markers.length > 0) {
			for ( i = 0; i < markers.length; i++) {
				markers[i].setMap(map);
			}
		}
	});
}

function showMarker(id) {
	markersIndex[id].setMap(map);
}

function hideMarker(id) {
	markersIndex[id].setMap(null);
}

function startMap() {
	// If a Map Canvas exists
	if ( jQuery( "#map-canvas" ).length ) {
		initializeMap('map-canvas');

			searchstart();

		//console.log(markersIndex);

		jQuery('.ls-item').on('click', function (){
			//console.log(jQuery(this).attr('datapin'));
			//console.log(markersIndex[jQuery(this).attr('datapin')]);

			new google.maps.event.trigger( markersIndex[jQuery(this).attr('datapin')], 'click' );
		});
	}
}
