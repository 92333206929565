jQuery(function($) {


  $.fn.extend({hideOptions: function() {
      var s = this;
      return s.each(function(i,e) {
          var d = $.data(e, 'disabledOptions') || [];
          $(e).find("option[disabled=\"\"]").each(function() {
              d.push($(this).detach());
          });
          $.data(e, 'disabledOptions', d);
      });
  }, showOptions: function() {
      var s = this;
      return s.each(function(i,e) {
          var d = $.data(e, 'disabledOptions') || [];
          for (var i in d) {
              $(e).append(d[i]);
          }
      });
  }});

  setInterval(function(){
    $('select').hideOptions();
  }, 1500);

  $('select').hideOptions();

  $(".product-finder").on("click", ".pf-selector", function() {
    $selector = $(this).find(".selector");
    //console.log($selector);
    $selector.slideToggle();
  });

  $(".product-finder").on("click", ".selector a", function(e) {
    /*e.preventDefault();
    $selected = $(this).attr("href");
    $selectedText = $(this).text();

    $(this).closest(".pf-input").find(".selected").text($selectedText);
    $(this).closest(".pf-input").find(".link").val($selected);*/

    //console.log($selector);
    //$selector.slideToggle();
  });

  $(".product-finder").on("click", ".search-btn", function(e) {
    e.preventDefault();
    $selected = $(this).parent().find(".link").val();

    if ($selected !== "#") {
      //$(".product-finder").find(".selector").hide();
      location.href = $selected;
    }
  });

  /*$('.product-finder-complete').on('click', '.main-category-list-container', function() {
    $container = $(this).parent().parent();


    console.log($(this).find('#main-category-list'));
    console.log(jQuery(this).html());

    $(this).find('#main-category-list').val(jQuery(this).html());
    $container.find('.main-category-list').slideToggle();
    $container.find('.hasDownArrow').toggleClass('sliding');
    $container.toggleClass('sliding');
  });*/

  $( "#main-category-list" ).click(function() {
    $('.main-category-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
  });

  $( "#sector-list" ).click(function() {
    $('.sector-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
  });

  $( "#under-category-list" ).click(function() {
    $('.under-category-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
  });

  $( "#product-site-list" ).click(function() {
    $('.product-site-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
  });

  $( "#tecnology-list" ).click(function() {
    $('.tecnology-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
  });


  function mega_filter(){
    var sel_category = $('#main-category-list').val();
    var sel_sector = $('#sector-list').val();
    var sel_sub_category = $('#under-category-list').val();
    var sel_product_site = $('#product-site-list').val();
    var sel_tecnology = $('#tecnology-list').val();
    var sel_name_product = $('#name-search').val();


    $('.products-accordion').each(function(){
      var final_check = true;
      var product_sectors = $(this).attr('meta-sector').split("|");
      var i;

      if(sel_name_product !== ""){
        var product_name = $(this).find(".panel-title").html();
        if(product_name.indexOf(sel_name_product) > -1){
          final_check = true;
        }
        else{
          final_check = false;
        }
      }

      if(sel_category !== "" && $(this).attr('meta-category') !== sel_category){
        final_check = false;
      }

      if(sel_sector !== ""){
        var verify = 0;
        for( i = 0, l = product_sectors.length; i < l; i++ ){
          if( product_sectors[i] === sel_sector){
            verify = 1;
          }
        }
        if(verify === 0){
          final_check = false;
        }
      }

      if(sel_sub_category !== "" && $(this).attr('meta-subcategory') !== sel_sub_category){
        final_check = false;
      }

      if(sel_product_site !== "" && $(this).attr('meta-productive-site') !== sel_product_site){
        final_check = false;
      }

      if(sel_tecnology !== "" && $(this).attr('meta-tecnology') !== sel_tecnology){
        final_check = false;
      }

      if(final_check === false){
        $(this).hide();
      }
      else{
        $(this).show();
      }

    });
  }

  function mega_filter_v2(main_category_id, sub_category_id, sector_id, site_id, technology_id, text_string, filter_caller) {
    console.log("mega_filter_v2");

    console.log("main_category_id:" + main_category_id);
    console.log("sub_category_id:" + sub_category_id);
    console.log("sector_id:" + sector_id);
    console.log("site_id:" + site_id);
    console.log("technology_id:" + technology_id);
    console.log("text_string:" + text_string);
    console.log("filter_caller:" + filter_caller);

    location.href="/trova-prodotto/" +
      "?main_category_id=" + main_category_id +
      "&sub_category_id=" + sub_category_id +
      "&sector_id=" + sector_id +
      "&site_id=" + site_id +
      "&technology_id=" + technology_id +
      "&text_string=" + text_string +
      "&filter_caller=" + filter_caller;
  }

  $(".main-category-list-container").on("click", ".filter-item-category", function(e) {
    console.log("filter-item-category click");

    var main_category_id = $(this).attr("data-id");

    var sub_category_id = "";
    var sub_category_id_value = $(document).find(".under-category-list ul li.select");
    if (sub_category_id_value.length !== 0) {
        sub_category_id = sub_category_id_value.attr("data-id");
    }

    var sector_id = "";
    var sector_id_value = $(document).find(".sector-list ul li.select");
    if (sector_id_value.length !== 0) {
        sub_category_id = sector_id_value.attr("data-id");
    }

    var site_id = "";
    var site_id_value = $(document).find(".product-site-list ul li.select");
    if (site_id_value.length !== 0) {
        sub_category_id = site_id_value.attr("data-id");
    }

    var technology_id = "";
    var technology_id_value = $(document).find(".technology-list ul li.select");
    if (technology_id_value.length !== 0) {
        sub_category_id = technology_id_value.attr("data-id");
    }

    var text_string = $("#name-search").val();
    /*var text_string = $(this).attr("data-id");
    if ($(this).find(".sector-list ul li.select").length != 0) {
        console.log($(this).find(".sector-list ul li.select"));
        console.log($(this).find(".sector-list ul li.select").attr("data-id"));
    }*/

    mega_filter_v2(
      main_category_id,
      sub_category_id,
      sector_id,
      site_id,
      technology_id,
      text_string,
      "main_category");
  });

  /*$( ".product-finder-complete" ).on ("click", ".filter-item-category", function(e) {
    $('.main-category-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
    $('#main-category-list').val($(this).html());

    mega_filter_v2();

    //mega_filter();
  });*/

  $( ".product-finder-complete" ).on ("click", ".filter-item-sector", function(e) {
    $('.sector-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
    $('#sector-list').val($(this).html());

    mega_filter();
  });

  $( ".product-finder-complete" ).on ("click", ".filter-item-under-category", function(e) {
    $('.under-category-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
    $('#under-category-list').val($(this).html());

    mega_filter();
  });

  $( ".product-finder-complete" ).on ("click", ".filter-item-product-site", function(e) {
    $('.product-site-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
    $('#product-site-list').val($(this).html());

    mega_filter();
  });

  $( ".product-finder-complete" ).on ("click", ".filter-item-tecnology", function(e) {
    $('.tecnology-list').slideToggle();
    $('.hasDownArrow').toggleClass('sliding');
    $('#tecnology-list').val($(this).html());

    mega_filter();
  });

  $("#name-search").keypress(function(e) {
    if(e.which === 13) {
        mega_filter();
    }
  });

  $( ".product-finder-complete" ).on ("click", "#btn-reset", function(e) {
    $('#main-category-list').val('');
    $('#sector-list').val('');
    $('#under-category-list').val('');
    $('#product-site-list').val('');
    $('#tecnology-list').val('');
    $('#name-search').val('');

    mega_filter();
  });




  // cloning categoria prodotto
  $("#beautiful-taxonomy-filters-tax-categoria-prodotto").parent().after(function() {

    var $clone = $(this).clone();
    $clone.find("select")
      .attr("id", "select-sotto-categoria-prodotto")
      .attr("name", "select-sotto-categoria-prodotto")
      .find('option:contains("Categoria prodotto")').text('Sottocategoria');

    return $clone;
  });


  $("#select-categoria-prodotto option").each(function(index, value) {

    //console.log(index);
    //console.log($(value).text());
    if (index > 0) {
      try { // in fondo alla select delle categorie, oltre a tutti i json mette una voce "categoria prodotto"
        var jsonValue = JSON.parse($(value).text());

        if (jsonValue.depth !== 0) {
          $(this).remove();
        } else {
          $(this).text(jsonValue.term_name);
          $(this).attr("data-term_id", jsonValue.term_id);
          $(this).attr("data-term_parent", jsonValue.parent);
        }
      }
      catch(err) {
        $(this).remove();
      }


    }
  });



  $("#select-sotto-categoria-prodotto option").each(function(index, value) {
    if (index > 0) {
      //console.log(index);

      var jsonValue = JSON.parse($(value).text());
      //console.log(jsonValue);
      if (jsonValue.depth !== 1) {
        $(this).remove();
      } else {
        $(this).text(jsonValue.term_name);
        $(this).attr("data-term_id", jsonValue.term_id);
        $(this).attr("data-term_parent", jsonValue.parent);
      }
    }

  });


  function filter_sub_categories() {

    var main_id = $("#select-categoria-prodotto").find(":selected").attr("data-term_id");

    $("#select-sotto-categoria-prodotto option").each(function(index, value) {
      if (index > 0) {

        if ($(this).attr("data-term_parent") !== main_id) {
          $(this).prop("disabled", true);
        } else {
          $(this).prop("disabled", false);
        }
      }
    });

  }

  $("#beautiful-taxonomy-filters-tax-categoria-prodotto").on("change", '#select-categoria-prodotto', function(e) {
    //console.log($(this));
    $("#select-sotto-categoria-prodotto").val("0");

    filter_sub_categories();
  });

  $(".submit-button").on("click", 'button', function(e) {
    var sub_selected = $('#select-sotto-categoria-prodotto').find(":selected").val();
    if (sub_selected !== "" && sub_selected !== "0") {
      $("#select-categoria-prodotto").append($('<option>', {
          value: sub_selected,
          text: ''
      }));
      $("#select-categoria-prodotto").val(sub_selected);
      //console.log(sub_selected);
    }
  });



  function select_main_category() {
    var sub_parent_id = $("#select-sotto-categoria-prodotto").find(":selected").attr("data-term_parent");

    if (sub_parent_id !== "" && sub_parent_id !== "0" && sub_parent_id !== undefined) {
      console.log(sub_parent_id);
      $("#select-categoria-prodotto").find("[data-term_id="+sub_parent_id+"]").prop('selected', true);
    }

    filter_sub_categories();
  }
  select_main_category();




  $( "#top-level-category-filter" ).on ("click", "#compositi-top", function(e) {
    $('.products-accordion').each(function(){
      if($(this).attr('meta-top-level') === 'COMPOSITI'  ||  $(this).attr('meta-top-level') === 'COMPOSITES'){
        $(this).show();
      }
      else{
        $(this).hide();
      }
    });
  });

  $( "#top-level-category-filter" ).on ("click", "#intermedi-top", function(e) {
    $('.products-accordion').each(function(){
      if($(this).attr('meta-top-level') === 'INTERMEDI'  ||  $(this).attr('meta-top-level') === 'INTERMEDIATES'){
        $(this).show();
      }
      else{
        $(this).hide();
      }
    });
  });

  $( "#top-level-category-filter" ).on ("click", "#coatings-top", function(e) {
    $('.products-accordion').each(function(){
      if($(this).attr('meta-top-level') === 'COATINGS'){
        $(this).show();
      }
      else{
        $(this).hide();
      }
    });
  });

});
