jQuery(function($) {
  function loadFilters(main_category_id, sub_category_id, sector_id, site_id, technology_id, filter_caller) {
    $.ajax({
  		url : ajaxurl,
  		type : 'POST',
      //dataType : "json",
  		data : {
        action: 'get_filters',
  			main_category_id : main_category_id,
        sub_category_id: sub_category_id,
        sector_id: sector_id,
        site_id: site_id,
        technology_id: technology_id,
        filter_caller: filter_caller
  		},
  		success : function( response ) {
        console.log(response);

        var values = JSON.parse(response);

        if (values.sub_categories) {
          $(".under-category-list ul").empty();

          $.each(values.sub_categories, function( index, value ) {
            //console.log( value );

            $(".under-category-list ul").append(
              $('<li></li>')
                .addClass('filter-item-under-category')
                .text(value.name.toLowerCase())
            );

          });
        }

        /*$(".under-category-list ul").empty();

        $.each(values, function( index, value ) {
          console.log( value );

          $(".under-category-list ul").append(
            $('<li></li>')
              .addClass('filter-item-under-category')
              .text(value.name.toLowerCase())
          );

        });*/
  		}
  	});
  }

  /*$(".main-category-list-container").on("click", ".filter-item-category", function(e) {
    var main_category_id = $(this).attr("data-id");
    var sub_category_id = null;
    var sector_id = $(this).attr("data-id");
    var site_id = $(this).attr("data-id");
    var technology_id = $(this).attr("data-id");

    loadFilters(main_category_id, sub_category_id, sector_id, site_id, technology_id, "main_category");
  });*/
});
