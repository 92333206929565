jQuery('.slide-in-bottom').waypoint(function(direction) {
		jQuery(this.element).animate({ top: 0, opacity: 1 }, 500);
	}, {
		offset: '80%'//'bottom-in-view'
	}
);

jQuery('.slide-in-right').waypoint(function(direction) {
		jQuery(this.element).animate({ left: 0, opacity: 1 }, 500);
	}, {
		offset: '80%'//'bottom-in-view'
	}
);

jQuery('.slide-in-left').waypoint(function(direction) {
		jQuery(this.element).animate({ left: 0, opacity: 1 }, 500);
	}, {
		offset: '80%'//'bottom-in-view'
	}
);

jQuery('.c-link').each(function(index){
    jQuery(this).delay(400*index).animate({ bottom: 0, opacity: 1 }, 500);
});


jQuery('.menu-item-has-children').hover(function () {
				jQuery(this).find('.sub-menu').stop().fadeIn(0);
});
jQuery('.menu-item-has-children').mouseleave(function() {
				jQuery(this).find('.sub-menu').stop().fadeOut(300);
});

jQuery(document).ready(function() {

	jQuery('.comunicazione-item .sub-menu').appendTo('.comunication-bar .langs-container');

	if(jQuery('body').hasClass("post-type-archive-corporate")){
		jQuery(".menu-item-object-corporate>a").css("color", "#4677ba");
		jQuery(".comunicazione-item a").css("color", "#4677ba");
	}
	else if((jQuery('body').hasClass("menu-item-object-multimedia")) || (jQuery('body').hasClass("post-type-archive-multimedia")) || (jQuery('body').hasClass("tax-categoria-multimedia"))){
		jQuery(".menu-item-object-multimedia>a").css("color", "#4677ba");
		jQuery(".comunicazione-item a").css("color", "#4677ba");
	}

	//jQuery(".location-items").customScrollbar();

	jQuery(window).scroll( function(){
	    jQuery('#dipendenti').each( function(i){

	        var bottom_of_object = jQuery(this).offset().top + jQuery(this).outerHeight();
	        var bottom_of_window = jQuery(window).scrollTop() + jQuery(window).height();

	        if( bottom_of_window > bottom_of_object ){

	            jQuery('#stabilimenti-produttivi').animate({'opacity':'1'},1000);
							jQuery('#ML-fatturato').delay(500).animate({'opacity':'1'},1000);
							jQuery('#dipendenti').delay(1000).animate({'opacity':'1'},1000);
							jQuery('#vendite').delay(1500).animate({'opacity':'1'},1000);

	        }
	    });
	});


});
