jQuery('.history_info').on("click", function() {
		jQuery('.descr_evento').fadeOut();
		jQuery('.event-list').css("opacity", 0);

		var id = jQuery(this).attr("data-id");

		setTimeout(function() {
			jQuery('.descr_evento').each( function(){
				if(jQuery(this).attr("data-id") === id){
					jQuery(this).fadeIn();
					jQuery('.event-list').css("opacity", 1);
				}
			});
		}, 500);

	jQuery('.point').css("background", "rgba(0,0,0,0)");
	jQuery(this).find('.point').css("background-color", "#4571b8");
});
