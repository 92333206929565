if( jQuery(window).width() > 768 ) {
	jQuery('.equal-columns').each(function() {
		var h = 0;

		jQuery(this).find('> div').each(function() {
			if(jQuery(this).innerHeight() > h) {
				h = jQuery(this).innerHeight();
			}
		});

		jQuery(this).find('> div').innerHeight(h);
	});

	jQuery('.equal-columns-hn').each(function() {
		var h = 0;

		jQuery(this).find('.b-home-news').each(function() {
			if(jQuery(this).innerHeight() > h) {
				h = jQuery(this).innerHeight();
			}
		});

		// This section is composed by one new on the left and two on the right.
		// The two on the right are supposed to be of same height
		// The single one has height = sum of the two on the right, plus 2 (that is two borders)
		jQuery(this).find('.b-home-news').innerHeight(h);
		jQuery(this).find('.b-first-news').innerHeight(h * 2 + 2);
	});

	jQuery('.safety-points').each(function() {
		var h = 0;

		jQuery(this).find('p').each(function(index) {
			if(jQuery(this).height() > h) {
				h = jQuery(this).innerHeight();
			}

			if(index % 2 !== 0) {
				jQuery('.safety-points').find('p').eq(index-1).innerHeight(h);
				jQuery('.safety-points').find('p').eq(index).innerHeight(h);
				h = 0;
			}
		});
	});
	/*
	jQuery('.sb-wall').each(function() {
		var h = 0;

		jQuery(this).find('.sb-item').each(function() {
			if(jQuery(this).height() > h) {
				h = jQuery(this).height();
			}
		});

		jQuery(this).find('.sb-item').height(h);
	});*/
}
