jQuery(document).ready(function() {

jQuery("#filter-companies").change(function() {
  var selected_company = jQuery("#filter-companies").attr("value");
  companyFilter(selected_company);
});

jQuery(".close-btn-filter").on("click", function(){
  jQuery("#index-box").fadeOut(100);
});

jQuery(".btn-search").on("click", function() {
  jQuery("#index-box").fadeIn(100);
});

jQuery('.country').on("click", function() {
  jQuery('.country-list').stop().slideToggle(150);
});

jQuery('.type').on("click", function() {
  jQuery('.type-list').stop().slideToggle(150);
});

jQuery(".ls-item-country").on("click", function() {

  var selected_country = jQuery(this).attr("country");

  jQuery(".ls-item").each(function() {
      jQuery(this).fadeIn(50);
  });

  console.log(selected_country);
  if((selected_country !== 'Tutti') && (selected_country !== 'All')) {
    jQuery(".ls-item").each( function() {
      if(jQuery(this).attr("meta-country") !== selected_country) {
        jQuery(this).fadeOut(50);
      }
    });
  }

  jQuery(".country > p").text(selected_country);
  jQuery(".country-list").slideUp();

  });

jQuery(".ls-item-type").on("click", function() {
  var selected_type = jQuery(this).attr("type");
  var selected_name = jQuery(this).text();

  jQuery(".ls-item").each( function() {
    jQuery(this).fadeIn(50);
  });
  jQuery(".ls-item").each(function() {
    if(jQuery(this).attr("meta-type") !== selected_type) {
      jQuery(this).fadeOut(50);
    }
  });
  jQuery(".type-list").slideUp();
  jQuery(".type > p").text(selected_name);
});

});
