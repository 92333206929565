jQuery(document).on({
	mouseenter: function () {
		if(jQuery( window ).width() >= 992 ) {
			jQuery(this).find('.sb-content').stop().animate({ opacity: 0 }, 300);
			jQuery(this).find('.sb-head').stop().animate({ opacity: 0 }, 300);
		}
	},
	mouseleave: function () {
		if(jQuery( window ).width() >= 992 ) {
			jQuery(this).find('.sb-content').stop().animate({ opacity: 1 }, 300);
			jQuery(this).find('.sb-head').stop().animate({ opacity: 1 }, 300);
		}
	}
}, '.square-badge');
