function setAccordionArrows(){
	jQuery('.products-accordion').each( function() {
			jQuery(this).removeClass('open');
	});

	jQuery('.products-accordion').each( function() {
		if(jQuery(this).find('.panel-collapse').hasClass('in')) {
			jQuery(this).addClass('open');
		}
	});
}

function slideProductFilter(){
	jQuery('.pf-content').slideToggle(600);
}

function firstPanelOpened(){
		jQuery('.panel-products-group .products-accordion .panel-collapse').removeClass('in');
		setTimeout( function() {
			jQuery('.panel-products-group .products-accordion:visible .panel-collapse').first().addClass('in');
		}, 2000);
}

jQuery(document).ready(function() {
  setTimeout(setAccordionArrows, 500);

	//single sector
  jQuery('.product-list .product-item').each(function(){
    var category = jQuery(this).attr("meta-cat");
    jQuery(this).appendTo('.panel-sector .panel.' + category + ' .panel-body h3 ');
  });

  jQuery('.product-filter .nation').each(function(){
    var continent = jQuery(this).attr("data-continent");
    jQuery(this).appendTo('.product-filter .continent.' + continent + ' ul ');
  });

	jQuery('.product-filter .nation-fn').each(function(){
		var continent = jQuery(this).attr("data-continent");
		jQuery(this).appendTo('.product-filter .continent.' + continent + ' ul ');
	});
});

jQuery('.product-filter .nation-fn').on("click", function(){
	var sel_nation = jQuery(this).attr("data-country");
	var value = jQuery(this).text();
//	console.log(value);
	jQuery('.pf-title').html('<p>' + value + '</p>');

	if(sel_nation === 'all'){
		jQuery('.products-accordion').each(function(){
			jQuery(this).stop().fadeIn(850);
		});
	}
	else{
		jQuery('.products-accordion').each(function(){
			jQuery(this).stop().fadeOut(250);
		});

		console.log(sel_nation);

		jQuery('.products-accordion').each(function() {
			if(jQuery(this).hasClass(sel_nation)) {
				jQuery(this).stop().fadeIn(850);
			}
		});
	/*	jQuery('.products-accordion' + '.' + sel_nation).each(function(){
		, function(){
				setTimeout(firstPanelOpened, 1000);
			});
		});*/
	}

//setTimeout(setAccordionArrows, 1000);
});

jQuery('.product-filter .nation').on("click", function(){
	var sel_nation = jQuery(this).attr("data-country");
	var value = jQuery(this).text();
//	console.log(value);
	jQuery('.pf-title').html('<p>' + value + '</p>');

	if(sel_nation === 'all'){
		jQuery('.products-accordion').each(function(){
			jQuery(this).stop().fadeIn(500, function(){
				setTimeout(firstPanelOpened, 1000);
			});
		});
	}
	else{
		jQuery('.products-accordion').each(function(){
			jQuery(this).stop().fadeOut(250);
		});

		jQuery('.products-accordion' + '.' + sel_nation).each(function(){
			jQuery(this).stop().fadeIn(500, function(){
				setTimeout(firstPanelOpened, 1000);
			});
		});
	}

setTimeout(setAccordionArrows, 1000);
});

jQuery('.panel-heading').on("click", function (){
 	setTimeout(setAccordionArrows, 500);
});

jQuery('.pf-title').on("click", function(){
	jQuery(this).toggleClass('opened');
	jQuery(this).toggleClass('closed');
	slideProductFilter();
	//jQuery(".product-filter .pf-content").customScrollbar();
});

jQuery('body').on("change", ".beautiful-taxonomy-filters-select", function(){
		jQuery('#beautiful-taxonomy-filters-form .submit-button button').click();
	});
