jQuery(document).ready(function() {

  jQuery('.fancybox-media').fancybox({

      padding     : 0,
      margin      : [20, 60, 20, 60], // Increase left/right margin
      maxWidth    : 800,
      closeBtn : false,
      maxHeight   : 600,
      arrows  : true,
      helpers : {
        media : {}
      }
  });
});

jQuery(document).ready(function() {
	jQuery(".fancybox-thumb").fancybox({
		prevEffect	: 'none',
		nextEffect	: 'none',
    arrows  : true,
		helpers	: {
			title	: {
				type: 'outside'
			},
			thumbs	: {
				width	: 100,
				height	: 100
			}
		}
	});
});
