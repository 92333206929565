jQuery( document ).ready(function() {
  $target = jQuery(".product-finder-container-menu");
  $source = jQuery("#product-finder-menu");
  $menu = jQuery(".item-pfinder");
  $target.show();

  $target.replaceWith( $source );

  jQuery( document ).on("click", ".item-pfinder", function(e) {
    e.preventDefault();
    $source.slideToggle();
  });

});
