jQuery('.icon-search').click(function(){
    jQuery('.search-bar').stop().slideToggle();
    jQuery('.lang-bar').stop().slideUp();
    jQuery('.comunication-bar').stop().slideUp();
});

jQuery('.icon-world').click(function(){
    jQuery('.lang-bar').stop().slideToggle();
    jQuery('.search-bar').stop().slideUp();
    jQuery('.comunication-bar').stop().slideUp();
});

jQuery('.comunicazione-item').click(function(){
    jQuery('.comunication-bar').stop().slideToggle();
    jQuery('.lang-bar').stop().slideUp();
    jQuery('.search-bar').stop().slideUp();
});
