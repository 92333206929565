jQuery(document).on({
    click: function () {
		if( jQuery(window).width() < 992 ) {
			jQuery('.cbp-spmenu div.nav').slideToggle(600);
			//jQuery('.menu-wrapper nav .nav').slideToggle(500);

      //prevents eng mobile menu click events
      jQuery("#menu-item-4324 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-4146 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-4154 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-4160 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-4327 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-4167 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-4172 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-4174 > a").click(function(event){event.preventDefault();});

      //prevents it mobile menu click events
      jQuery("#menu-item-714 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-1177 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-97 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-1172 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-197 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-39 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-40 > a").click(function(event){event.preventDefault();});
      jQuery("#menu-item-41 > a").click(function(event){event.preventDefault();});
		}
		else {
			window.location = "/";
			return false;
		}
    }
}, '.menu-switcher span');

var sticky = new Waypoint.Sticky({
	element: jQuery('.menu-affix')[0]
});

jQuery(document).on({
    click: function (e) {
		e.preventDefault();
		var toOpen = jQuery(this).parent().find('.sub-menu');

		if(jQuery('.menu-wrapper nav').hasClass('second-level-open') && !toOpen.hasClass('opensub')) {
			jQuery('.menu-wrapper nav').toggleClass('second-level-open');
			jQuery('.sub-menu.opensub').toggle(300, function() {
				jQuery('.menu-wrapper nav').toggleClass('second-level-open');
				jQuery('.sub-menu.opensub').removeClass('opensub');
        jQuery('html').css({'overflow-y':'scroll'});
				toOpen.toggle(300);
				toOpen.toggleClass('opensub');
			});
		}
		else {
			if(!toOpen.hasClass('opensub')) {
				jQuery('.menu-wrapper nav').toggleClass('second-level-open');
				jQuery('.sub-menu.opensub').toggle(300);
				jQuery('.sub-menu.opensub').removeClass('opensub');
				toOpen.toggle(300);
				toOpen.toggleClass('opensub');
			}
			else {
				jQuery('.menu-wrapper nav').toggleClass('second-level-open');
				jQuery('.sub-menu.opensub').toggle(300);
				jQuery('.sub-menu.opensub').removeClass('opensub');
			}
		}
    }
}, '.has-submenu > a');

// Resize inner UL
jQuery(function() {
	if( jQuery(window).width() < 992 ) {
		jQuery('.menu-wrapper .nav ul.nav').height(jQuery( window ).height() );
		jQuery('.menu-wrapper .nav ul.nav').css('overflow-y', 'scroll');
	}
});

// Hide the menu if a click is performed outside of them
jQuery(document).mouseup(function (e)
{
 var container = jQuery(".menu-wrapper");

 if (!container.is(e.target) && container.has(e.target).length === 0)
 {
  if(jQuery('#cbp-spmenu-s1').hasClass('second-level-open')){
   jQuery('.has-submenu .opensub').first().parent().find('a').click();
  }
 }
});
