jQuery(document).on({
	click: function (e) {
    e.preventDefault();
		console.log('Create repeater block');
		var clone = jQuery(this).parent().parent().find(".repeater-base").clone().removeClass("repeater-base");
		
		clone.find('input').each (function(){
			jQuery(this).val('');
		});
		
		clone.appendTo(jQuery("#repeater-placeholder"));
		
		jQuery(this).parent().parent().find("#repeater-placeholder, .repeater-base").find('input').each( function() {
			var orig = jQuery(this).attr('name');
			jQuery(this).attr('name', orig + "[]");
		});
	}
}, '#repeater-btn');

jQuery(document).ready( function() {
	jQuery("#survey").fancybox({
		maxWidth	: 1000,
		maxHeight	: 900,
		fitToView	: false,
		width 		: '85%',
		height 		: '85%',
		autoSize	: false,
		closeClick	: false,
		openEffect	: 'none',
		closeEffect	: 'none',
		type		: 'iframe'
	});
});
